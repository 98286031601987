<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.user.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>公户管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item :label="$t('gjz')">
                                    <el-input v-model="searchform.blurry" @keyup.enter.native="onSubmit" size="small" placeholder="账号">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="商户名称">
                                    <el-select size="small" v-model="searchform.mchId" style="width:180px;" :placeholder="$t('qxz')"  >
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="(item,index) in mchlist" :label="item.nickName" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="searchright">
                            <el-button size="small" type="primary" @click="openadd">+添加公户</el-button>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                        stripe border style="width: 100%">
                        <el-table-column prop="cuenta" label="公户账号"></el-table-column>
                        <el-table-column prop="bankName" label="公户所属银行"></el-table-column>
                        <el-table-column prop="cuentaName" label="账户受益人姓名"></el-table-column>
                        <el-table-column prop="maxPasta" label="上线金额"></el-table-column>
                        <el-table-column prop="mchName" label="绑定商户"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.status" active-color="#409EFF" inactive-color="#F56C6C"/>
                            </template>
                        </el-table-column>
                        <el-table-column prop="type" label="还款方式"></el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="180">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;">
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
        <pop-up :title="status==1? '添加公户' : '编辑公户'" width="30%" :isshow="showadd" 
            :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small" label-width="160px">
                    <el-form-item label="公户账户" prop="cuenta">
                        <el-input v-model="form.cuenta" :disabled="status==2 ? true : false"/>
                    </el-form-item>
                    <el-form-item label="账户受益人姓名" prop="cuentaName">
                        <el-input v-model="form.cuentaName"/>
                    </el-form-item>
                    <el-form-item label="公户所属银行" prop="bankId">
                        <el-select size="small" v-model="form.bankId" filterable :placeholder="$t('qxz')">
                            <el-option :label="$t('qxz')" :value="null"></el-option>
                            <el-option v-for="item in accountlist"
                                :key="item.value"
                                :label="item.desc"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上线金额" prop="maxPasta">
                        <el-input-number v-model.number="form.maxPasta" />
                    </el-form-item>
                    <el-form-item label="绑定商户" >
                        <el-select v-model="form.mchId" :placeholder="$t('qxz')">
                            <el-option v-for="item in mchlist" :key="item.id" :label="item.nickName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="还款方式">
                        <el-radio-group v-model="form.type">
                            <el-radio label="OXXO">OXXO</el-radio>
                            <el-radio label="CARDPAY">CARDPAY</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('status')">
                        <el-radio-group v-model="form.status">
                            <el-radio :label="true">{{$t('jihuo')}}</el-radio>
                            <el-radio :label="false">冻结</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/sys"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                if (value[0].id) {
                    callback()
                } else {
                    callback(new Error(this.$t('qxzrole')));
                }
            }
            return {
                form: {
                    id: null,
                    cuenta: null,
                    maxPasta: null,
                    mchName: null,
                    mchId: null,
                    cuentaName: null,
                    type: null,
                    status: null,
                    bankId: null,
                    bankName: null,
                },
                searchform: {
                    blurry: "",
                },
                loading: false,
                showadd: false,
                list: [],
                total: 1,
                pageNo: 1,
                pageSize: 10,
                mchlist: [],
                status: 1,
                accountlist: [],
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getmchlist()
            this.getaccount()
        },
        methods: {
            getaccount() {
                _api.get(apiurl.bankType, {
                }).then(res => {
                    this.accountlist = res.data
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    cuenta: null,
                    maxPasta: null,
                    mchName: null,
                    mchId: null,
                    cuentaName: null,
                    type: null,
                    status: null,
                    bankId: null,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.form.cuenta = row.cuenta
                this.form.maxPasta = row.maxPasta
                this.form.mchName = row.mchName
                this.form.mchId = row.mchId
                this.form.status = row.status
                this.form.type = row.type
                this.form.bankId = row.bankId
                this.form.cuentaName = row.cuentaName
                this.showadd = true
            },
            getList() {
                this.loading = true
                _api.get(apiurl.hogares, {
                    page: this.pageNo,
                    size: this.pageSize,
                    blurry: this.searchform.blurry,
                    mchId: this.searchform.mchId,
                }).then(res => {
                    this.loading = false
                    this.list = res.data.list
                    this.total = res.data.total
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            getmchlist(){
                _api.get(apiurl.users, {
                    nation:localStorage.getItem("nation"),
                    roleId: 2,
                }).then(res => {
                    this.mchlist = res.content
                })
            },
            getconfirm() {
                if (this.status == 1) {
                    _api.post(apiurl.hogares, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            cuenta: null,
                            maxPasta: null,
                            mchName: null,
                            mchId: null,
                            cuentaName: null,
                            type: null,
                            status: null,
                            bankId: null,
                        }

                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.hogares, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            cuenta: null,
                            maxPasta: null,
                            mchName: null,
                            mchId: null,
                            cuentaName: null,
                            type: null,
                            status: null,
                            bankId: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
            },

            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            
            handleNodeClick(mydata, node, obj) {
                this.searchform.blurry = ""
                this.loading = true
                this.pageNo=1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                console.log(data)
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>